import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout/layout"
import favicon from "../images/favicon.ico"
import imgCardHero from "../images/marketing/svg/credit-card/card-hero.svg"
import imgCurrency from "../images/marketing/svg/credit-card/currency.svg"
import imgBrand from "../images/marketing/svg/credit-card/brand.svg"
import imgCash from "../images/marketing/svg/credit-card/cash.svg"
import imgGeneric from "../images/marketing/svg/credit-card/generic.svg"
import imgReward from "../images/marketing/svg/credit-card/reward.svg"
import imgTravel from "../images/marketing/svg/credit-card/travel.svg"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"

const choices = [
  {
    imgSrc: imgCurrency,
    description: "Best for statements above $5,000",
  },
  {
    imgSrc: imgBrand,
    description: "Best for Brands",
  },
  {
    imgSrc: imgGeneric,
    description: "Best for Generics",
  },
  {
    imgSrc: imgCash,
    description: "Best for Cash-Back",
  },
  {
    imgSrc: imgReward,
    description: "Best for Rewards",
  },
  {
    imgSrc: imgTravel,
    description: "Best for Travel",
  },
]

const CreditCardPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>EzriRx.com - Credit card advantage</title>
        <meta name="keywords" content="ezrirx, pharmacy purchasing platform" />
        <meta name="description" content="EzriRx - credit card advantage" />
        <meta property="og:title" content="EzriRx | Credit card advantage" />
        <link rel="canonical" href="https://www.ezrirx.com/credit-card/" />
        <link rel="shortcut icon" type="image/png" href={`${favicon}`} />
      </Helmet>
      <Layout>
        <div className="body card-container">
          <section className="hero-banner" id="hero-credit-card">
            <div className="contents">
              <div className="hero-banner__inner">
                <div className="hero-content">
                  <h1>
                    Find the perfect credit card for your pharmacy's needs
                  </h1>
                  <p>
                    Explore options for cash back, rewards, and travel benefits
                    tailored to the unique needs of pharmacies. Spend smarter,
                    save more!
                  </p>
                  <a
                    className="btn-learn-more"
                    rel="nofollow"
                    target="_blank"
                    href="https://www.cardratings.com/bestcards/featured-credit-cards?src=693350&shnq=4028089,4048264,5048295,340040,4048084,4048251"
                  >
                    Find card!
                  </a>
                </div>
                <div className="hero-image">
                  <img src={imgCardHero} alt="Credit card"></img>
                </div>
              </div>
            </div>
          </section>

          <section className="marketing-content">
            <div className="contents">
              <div className="card-pharmacy">
                <div className="card-pharmacy__title title-box text-center">
                  <h2>The Right Card for Your Pharmacy</h2>
                </div>
                <p className="card-pharmacy__description text-center">
                  From cash back and travel rewards to low-interest options,
                  find the perfect card to support your pharmacy’s goals.
                  Compare your choices below and pick the card that works best
                  for you.
                </p>
                <div className="card-choices">
                  <div className="row">
                    {choices.map((choice, index) => (
                      <div key={index} className="col-6 col-lg-4 card-choice">
                        <div className="card-choice__box">
                          <div className="card-choice__img">
                            <img
                              src={choice.imgSrc}
                              alt={choice.description}
                            ></img>
                          </div>
                          <p className="card-choice__desc text-center">
                            {choice.description}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="contents text-center">
            <div className="card-advantage">
              <h2>
                “Earning an additional 2.5% means our Brands <br /> go from
                -3.5% to -6% without a GCR Requirement on select items”
              </h2>
              <p>
                “By using a Cash-Back card, our pharmacy gained in two ways, we
                went from negative cash flow to positive cash flow by having an
                additional 30 days to pay our invoices, and actually became
                profitable on Brands as we are buying it better while not
                affecting our Primary GCR contract. We split our purchases
                accordingly.”
              </p>
              <p>
                “With a massive points buildup, we ended up taking a family
                vacation for the first time in years”
              </p>
              <p>
                It was crucial for us to learn the art of using the correct
                credit card at the right moment.
              </p>
              <a
                className="btn-learn-more mt-3"
                rel="nofollow"
                target="_blank"
                href="https://www.cardratings.com/bestcards/featured-credit-cards?src=693350&shnq=4028089,4048264,5048295,340040,4048084,4048251"
              >
                Find card!
              </a>
              <br />
              <OverlayTrigger
                className="advertising-box"
                placement="auto"
                show={true}
                overlay={
                  <Tooltip>
                    <p className="advertising-box__content">
                      The offers displayed on this page are based on
                      partnerships with participating companies. Compensation
                      from these partnerships may influence the placement and
                      order of products but does not impact our evaluation or
                      recommendations. All featured products are assessed
                      independently for their relevance to pharmacy
                      professionals.
                    </p>
                  </Tooltip>
                }
              >
                <p className="advertising-box__title">Advertising Disclosure</p>
              </OverlayTrigger>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

export default CreditCardPage
